// /* Base */
:root{
  scroll-behavior: smooth;
}
body {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 100vh;
  animation: body 1s linear;
  // background-image: url(../src/imgs/texture.png);
  // background-repeat: no-repeat;
  // background-size: cover;
  div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: left;
    min-height: 100vh;
    width: 100%;
    // padding: 0 100px 0 100px;
    // &.centered{
    //   padding: 0 500px;
    // }
    .init{
      background: linear-gradient(180deg, #AD88C6 0%, #e6d6e7 100%);
      .sobre{
        background-color: white;
        width: 300px;
        height: 160px;
        min-height: 0;
        border-radius: 10px;
        border: 2px solid black;
        position: relative;
        h4{
          margin-block-start: 15px; /* Elimina el margen superior */
          margin-block-end: 0; /* Elimina el margen inferior */
        }
        .heart{
          // margin-top: 100px;
          height: 40px;
          width: 40px;
          background-color: red;
          min-height: 0;
          padding: 0;
          position: relative;
          transform: rotate(-45deg);
          box-shadow: -10px 10px 90px #f20044;
          animation: heart 0.6s linear infinite;
        }
        @keyframes heart {
          0%{
            transform: rotate(-45deg) scale(1.10);
          }
          80%{
            transform: rotate(-45deg) scale(.90);
          }
          100%{
            transform: rotate(-45deg) scale(1.10);
          }
        }
        .heart::before{
          content: '';
          position: absolute;
          height: 40px;
          width: 40px;
          background: red;
          top: -50%;
          border-radius: 50px;
          box-shadow: -10px -10px 90px #f20044;
        }
        .heart::after{
          content: '';
          position: absolute;
          height: 40px;
          width: 40px;
          background: red;
          right: -50%;
          border-radius: 50px;
          box-shadow: 10px 10px 90px #f20044;
        }
      }
    }
    .centered{
      width: 80%;
    }
    .part-One{
      background: linear-gradient(180deg, #e6d6e7 0%, #f2ebf3 100%);
    }
    .part-Two{
      // background-color: #f2ebf3!important;
      background: linear-gradient(180deg, #f2ebf3 0%, #FFE5E5 100%);
    }
    .part-Three{
      // background-color: #f2ebf3!important;
      background: linear-gradient(180deg, #FFE5E5 0%, #e6d6e7 100%);
    }
    .part-Four{
      // background-color: #f2ebf3!important;
      background: linear-gradient(180deg, #e6d6e7 0%, #E2CFEA 100%);
    }
    .part-Five{
      // background-color: #f2ebf3!important;
      background: linear-gradient(180deg, #E2CFEA 0%, #d0f7ef 100%);
    }
  }
  
}
